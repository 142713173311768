import useRouteData from '../useRouteData'

import type { Scope } from '~/config/types'

const useScope = (): Scope => {
    const data = useRouteData<{ scope: Scope }>('root')

    return data?.scope || {}
}

export default useScope